import { types } from "../../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 5,
    },
    openFormGroup: false,
    openFormReport: false,
    active: null,
    groupsByCurriculum: [],
    list: [],
};

export const coordinationReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.coordinationList:
            return {
                ...state,
                list: action.payload,
            };
        case types.coordinationShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.coordinationsOpenModal:
            return {
                ...state,
                openFormGroup: true,
            };

        case types.coordinationsCloseModal:
            return {
                ...state,
                openFormGroup: false,
            };
        case types.coordinationsOpenReportModal:
            return {
                ...state,
                openFormReport: true,
            };
        case types.coordinationsCloseReportModal:
            return {
                ...state,
                openFormReport: false,
            };

        case types.coordinationSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.coordinationUnsetActive:
            return {
                ...state,
                active: null,
            };
        default:
            return state;
    }
};
