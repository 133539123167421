import React from "react";
import { useSelector } from "react-redux";
import { NotificationApp } from "./NotificationApp";

export const NotificationContainerApp = () => {
    const { notifications } = useSelector((state) => state.ui);

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            style={{
                position: "fixed",
                top: 100,
                right: 20,
            }}
        >
            {notifications.map((n) => (
                    <NotificationApp key={n.id} notification={n} />
                ))}
        </div>
    );
};

