import { combineReducers } from "redux";
import thunk from "redux-thunk";

//Reducers
import { configReducer } from "./reducers/administrador/config/configReducer";
import { uiReducer } from "./reducers/administrador/ui/uiReducer";
import { dashboardReducer } from "./reducers/dashboardReducer";
import { globalReducer } from "./reducers/globalReducer";

//users
import { authReducer } from "./reducers/administrador/auth/authReducer";
import { modulesReducer } from "./reducers/administrador/modules/modulesReducer";
import { delayReducer } from "./reducers/users/delayReducer";
import { userReducer } from "./reducers/users/userReducer";
//employee
import { daysOffReducer } from "./reducers/users/Incidents/daysOffReducer";
import { incidentsReducer } from "./reducers/users/Incidents/incidentsReducer";
import { departmentsReducer } from "./reducers/users/departmentsReducer";
import { employeeReducer } from "./reducers/users/employees/employeeReducer";
import { scheduleReducer } from "./reducers/users/employees/scheduleReducer";
import { eventsReducer } from "./reducers/users/eventReducer";
import { formatsReducer } from "./reducers/users/formatsReducer";
import { manualsReducer } from "./reducers/users/manualsReducer";
import { organizationReducer } from "./reducers/users/organizationReducer";
import { supportTIReducer } from "./reducers/users/supportTIReducer";
//teachers
import { punchesReducer } from "./reducers/teachers/session/punchesReducer";
import { teacherReducer } from "./reducers/teachers/teacherReducer";
import { PlanningSheets } from "./slices/users/PlanningSheets";
// teachers payroll v1
import { calendarReducer } from "./reducers/teachers/v1/calendarReducer";
import { justificationReducer } from "./reducers/teachers/v1/justificationReducer";
import { payrollReducer } from "./reducers/teachers/v1/payrollReducer";
import { payrollTeacherDetailReducer } from "./reducers/teachers/v1/payrollTeacherDetailReducer";
import { payrollTeacherReducer } from "./reducers/teachers/v1/payrollTeacherReducer";
import { tabulatorReducer } from "./reducers/teachers/v1/tabulatorReducer";
//teachers payroll v2
import { coordinationReducer } from "./reducers/teachers/v2/coordinationReducer";
import { curriculaReducer } from "./reducers/teachers/v2/curriculaReducer";
import { cutspayrollReducer } from "./reducers/teachers/v2/cutspayrollReducer";
import { cyclesReducer } from "./reducers/teachers/v2/cyclesReducer";
import { groupReducer } from "./reducers/teachers/v2/groupReducer";
import { justifyReducer } from "./reducers/teachers/v2/justifyReducer";
import { modulesTeacherReducer } from "./reducers/teachers/v2/modulesTeacherReducer";
import { payrollReducer as payrollReducerV2 } from "./reducers/teachers/v2/payrollReducer";
import { scheduleTeachersReducer } from "./reducers/teachers/v2/scheduleTeachersReducer";
import { schoolsubjectReducer } from "./reducers/teachers/v2/schoolsubjectReducer";
import { tabulatorReducer as tabulatorV2Reducer } from "./reducers/teachers/v2/tabulatorReducer";
//Students
import { algebraixReducer } from "./reducers/students/algebraixReducer";
import { careersReducer } from "./reducers/students/plans_and_study_programs/careersReducer";
import { practiceProfessionalReducer } from "./reducers/students/plans_and_study_programs/practiceProfessionalReducer";
import { serviceSocialReducer } from "./reducers/students/plans_and_study_programs/serviceSocialReducer";
import { studentReducer } from "./reducers/students/studentsReducer";
//ead

import { EADReducer } from "./reducers/teachers/EAD/EADReducer";
//Seccion de compras(shopping)
import { academicGroupsReducer } from "./reducers/operators/academicGroupsReducer";
import { electronicTitlesReducer } from "./reducers/students/electronicTitlesReducer";
import { budgetsReducer } from "./reducers/users/shopping/budgetsReducer";
import { providersReducer } from "./reducers/users/shopping/providersReducer";
import { purchasesReducer } from "./reducers/users/shopping/purchasesReducer";

//services
import { banbajioReducer } from "./reducers/services/banbajioReducer";
import { bbvaReducer } from "./reducers/services/bbvaReducer";
import { payuReducer } from "./reducers/services/payuReducer";

//Infrastructure
import { buildingReducer } from "./reducers/operators/buildingReducer";
import { classroomReducer } from "./reducers/operators/classroomReducer";
//import { coordinationReducer } from "./reducers/operators/CoordinationReducer";

//Curricula tabulators
import { curriculaTabulatorsReducer } from "./reducers/operators/curriculaTabulatorsReducer";

//* Redux toolkit
import { configureStore } from "@reduxjs/toolkit";

import sidebarReducer from "./slices/sidebarSlice";
import billingStatusReducer from "./slices/students/billingStatus";

import { scheduleStudentReducer } from "../redux/reducers/operators/scheduleStudentReducer";

import scholarshipRequestScreen from "./slices/students/ScholarshipRequestScreen";
import scholarshipScreen from "./slices/users/ScholarshipScreen";

//CalendarSchedule reducer
import { calendarScheduleReducer } from "./reducers/operators/calendarScheduleReducer";

//Payments
import paymentScreen from "./slices/users/PaymentScreen";

//Add student app reducer
import addStudentReducer from "./slices/components/AddStudentsApp";

//Students Extra payment
import scholarServicesReducer from "./slices/students/scholarServices";

//Shopping Cart App
import shoppingCartReducer from "./slices/components/ShoppingCartApp";

//*convention
import conventionReducer from "./slices/students/conventions";
//*discounts
import discountsReducer from "./slices/students/discounts";

import teacherGroupsReducer from "./slices/teachers/TeacherSession/TeacherGroupsSlice";

import GradesByStudentReducer from "./slices/components/GradesByStudentApp";

import PositiveBalanceScreenReducer from "./slices/users/PositiveBalanceScreen";

//*Scholar subjects by teacher
import subjectsByStudentAppReducer from "./slices/components/SubjectsByStudentApp";

//*Planning sheets
import planningSheetsReducer from "./slices/users/PlanningSheets";

//*School controls
import schoolControlsReducer from "./slices/users/SchoolControls";

import statusChangeReducer from "./slices/students/statusChange";

import GradesEditScreenReducer from "./slices/users/GradesEditScreen";

import GradesReportScreenReducer from "./slices/users/GradesReportScreen";

import GenericReportsReducer from "./slices/components/GenericReports";

import studentClarificationsReducer from "./slices/students/studentsClarification";

//*Students load documents
import loadDocumentsReducer from "./slices/students/loadDocuments";
import marketCashierScreen from "./slices/users/MarketCashierSlice";
import academicAttendanceReducer from "./slices/teachers/academicAttendance";
import studentInvoiceReducer from "./slices/students/studentInvoice";
import SchoolOfOriginsReducer from "./slices/users/SchoolOfOrigins";
import ChargeBySubjectSlice from "./slices/operators/ChargeBySubjectSlice";
import InscriptionSummary from "./slices/users/InscriptionSummary";
import EndOfMonth from "./slices/users/EndOfMonth";
import DistributionLists from "./slices/users/DistributionLists";
import News from "./slices/users/News";
import GradesBySubject from "./slices/users/GradesBySubject";
import AveragesReports from "./slices/users/AveragesReports";
import Enterprises from "./actions/administrador/config/Enterprises";
import ExtracurricularSubjects from "./slices/users/ExtracurricularSubjects";
import DebtsByMonth from "./slices/users/DebtsByMonth";
import InscriptionsByCycle from "./slices/users/InscriptionsByCycle";

//* Modal

const rootReducer = combineReducers({
    //administration
    ui: uiReducer,
    config: configReducer,
    enterprises: Enterprises,
    auth: authReducer,
    dashboard: dashboardReducer,
    global: globalReducer,
    //Users
    user: userReducer,
    modules: modulesReducer,
    supportTI: supportTIReducer,
    algebraix: algebraixReducer,
    manuals: manualsReducer,
    formats: formatsReducer,
    dayOff: daysOffReducer,
    events: eventsReducer,
    delay: delayReducer,

    //Employees
    organization: organizationReducer,
    departments: departmentsReducer,
    punches: punchesReducer,
    employees: employeeReducer,
    incidents: incidentsReducer,
    scheduleEmployee: scheduleReducer,
    //Seccion de compras(shopping)
    providers: providersReducer,
    purchases: purchasesReducer,
    budgets: budgetsReducer,
    //Teachers
    teacher: teacherReducer,
    socialService: serviceSocialReducer,
    practiceProfessional: practiceProfessionalReducer,
    careers: careersReducer,
    planningSheets: planningSheetsReducer,
    // v1
    payroll: payrollReducer,
    payrollTeacher: payrollTeacherReducer,
    payrollTeacherDetail: payrollTeacherDetailReducer,
    calendar: calendarReducer,
    tabulator: tabulatorReducer,
    justification: justificationReducer,
    //EAD
    EAD: EADReducer,
    // v2
    moduleteacher: modulesTeacherReducer,
    cycle: cyclesReducer,
    group: groupReducer,
    cutspayroll: cutspayrollReducer,
    curricula: curriculaReducer,
    schoolSubject: schoolsubjectReducer,
    scheduleTeachers: scheduleTeachersReducer,
    tabulatorsv2: tabulatorV2Reducer,
    payrollV2: payrollReducerV2,
    justify: justifyReducer,
    coordination: coordinationReducer,
    //students
    students: studentReducer,
    studentClarifications: studentClarificationsReducer,
    electronicTitles: electronicTitlesReducer,
    //Servicios
    banbajio: banbajioReducer,
    bbva: bbvaReducer,
    payu: payuReducer,
    //Operators
    academicGroups: academicGroupsReducer,
    //infrastructure
    building: buildingReducer,
    classroom: classroomReducer,
    //Coordination    //Curricula Tabulators
    curriculaTabulators: curriculaTabulatorsReducer,
    //Sidebar: RTK
    sidebar: sidebarReducer,
    //Schedule
    scheduleStudents: scheduleStudentReducer,
    // Billing status: RTK
    billingStatus: billingStatusReducer,
    //Scholarship request: RTK
    scholarshipRequest: scholarshipRequestScreen,
    //CalendarSchedule reducer
    calendarSchedule: calendarScheduleReducer,
    //Scholarship (operators): RTK
    scholarship: scholarshipScreen,
    // Payments: RTK
    payments: paymentScreen,
    // AddStudentApp RTK
    addStudentApp: addStudentReducer,
    // Extra payments: RTK
    scholarServices: scholarServicesReducer,
    // Shopping cart: RTK
    shoppingCartApp: shoppingCartReducer,
    //Conventions
    conventions: conventionReducer,
    //Discounts
    discounts: discountsReducer,
    //TeacherGroups: RTK
    teacherGroups: teacherGroupsReducer,
    //Grades By Student: RTK
    gradesByStudentApp: GradesByStudentReducer,
    //Positive valance
    positiveBalance: PositiveBalanceScreenReducer,
    // Subjects By Student: RTK
    subjectsByStudentApp: subjectsByStudentAppReducer,
    //School controls: RTK
    schoolControls: schoolControlsReducer,
    //status change: RTK
    statusChange: statusChangeReducer,
    //Grades edit screen: RTK
    gradesEditScreen: GradesEditScreenReducer,
    //Grades report screen: RTK
    gradesReportScreen: GradesReportScreenReducer,
    //Generic reports reducer for generic reports
    genericReports: GenericReportsReducer,
    //Students load documents
    loadDocuments: loadDocumentsReducer,

    marketCashier: marketCashierScreen,
    //students attendance academicAttendanceReducer
    studentsAttendace: academicAttendanceReducer,
    //INVOICES
    studentInvoices: studentInvoiceReducer,

    SchoolOfOrigins: SchoolOfOriginsReducer,

    chargeBySubject: ChargeBySubjectSlice,

    inscriptionSummary: InscriptionSummary,

    endOfMonth: EndOfMonth,

    distributionLists: DistributionLists,
    news: News,

    averagesReports: AveragesReports,
    gradesBySubject: GradesBySubject,
    extracurricularSubjects: ExtracurricularSubjects,
    debtsByMonth: DebtsByMonth,
    inscriptionsByCycle: InscriptionsByCycle,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
    devTools: process.env.NODE_ENV === "development",
});
