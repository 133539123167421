import {
    Call,
    objectToFormData,
    CallWithFormData,
    cleanEmpty,
} from "../../../../helpers/fetch";
import { types } from "../../../types";
import { startUILoading, stopUILoading } from "../../administrador/ui/ui";
import { errorHandler } from "../../../../helpers/errorHandler";
import Swal from "sweetalert2";
import { deleteConfirmModal } from "../../../../helpers/DeleteValidate";
import { startGetConfig } from "../../administrador/config/configs";

export const startShow = ({
    page = 1,
    query = "",
    number_rows = 10,
    asList = 0,
    period = "",
    only_current_cycles = false,
    emptyOption = false
}) => {
    return async (dispatch) => {
        if (!asList) dispatch(startUILoading());

        const params = new URLSearchParams();

        if (period) {
            params.set("period", period);
        }

        if (only_current_cycles) {
            params.set("only_current_cycles", only_current_cycles);
        }

        params.set("page", page);
        params.set("q", query);
        params.set("per_page", number_rows);
        params.set("list", asList);


        const resp = await Call("teachers/v2/cycle", "GET", params.toString());


        if (resp.success) {
            const data = resp.success.cycles;

            if (asList) {
                if (emptyOption) {
                    dispatch(list([...data, { id: 0, name: "Sin ciclo" }]));
                } else {
                    dispatch(list(resp.success.cycles));
                }

            } else {
                dispatch(show(data));
            }
        }

        if (!asList) dispatch(stopUILoading());
    };
};

export const startCreateCycle = (values, setErrors = null) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        // values = cleanEmpty(values);

        const resp = await Call(
            "teachers/v2/cycle/create",
            "PUT",
            values,
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startUpdateCycle = (values, setErrors = null) => {
    return async (dispatch) => {
        await dispatch(startUILoading());
        values = cleanEmpty(values);

        const resp = await Call(
            `teachers/v2/cycle/update/${values.id}`,
            "PUT",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Modificación exitosa.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startCurrentCycle = (current) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Quieres cambiar el ciclo escolar?",
            icon: "warning",
            text: "Se cambiara el ciclo escolar a " + current.name,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, Cambiar!",
        });

        if (result.value) {
            const formData = new FormData();

            formData.append("current_cycle", current.id);

            const resp = await CallWithFormData(
                "auth/config/save",
                "POST",
                formData
            );

            if (resp.success) {
                Swal.fire({
                    title: 'Se cambio el ciclo',
                    text: "Ahora el ciclo en curso es " + current.name,
                    icon: 'success',
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                })

                dispatch(startGetConfig())
            }
        }
    };
};

export const startCycleDestroy = (id, currentValues) => {
    return async (dispatch) => {
        await deleteConfirmModal(async () => {
            dispatch(startUILoading());
            const resp = await Call(`teachers/v2/cycle/destroy/${id}`, "DELETE");
            if (resp.success) {
                dispatch(startShow(currentValues));
                Swal.fire({
                    title: 'Se elimino el ciclo',
                    text: "Eliminado correctamente",
                    icon: 'success',
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                })
            } else {
                errorHandler(resp);
            }
            dispatch(stopUILoading());
        });
    };
};

export const startSetCyclesByUser = (cycles) => ({
    type: types.CyclesByUsers,
    payload: cycles
});

export const startSetActive = (cycle) => ({
    type: types.CycleSetActive,
    payload: cycle
});

export const startUnsetActive = () => ({
    type: types.CycleUnsetActive,
});
export const startOpenModal = () => ({
    type: types.CycleOpenModal,
});

export const startCloseModal = () => ({
    type: types.CycleCloseModal,
});

const show = (data) => ({
    type: types.CyclesShow,
    payload: { data },
});
export const list = (data) => ({
    type: types.CyclesList,
    payload: data,
});

// export const addemptyCycle = () => ({
//     type: types.CycleEmpty,
//     payload: {label: "Sin ciclo", value: "sin ciclo"},
// });
