import { types } from "../../types";
const initialState = {
    calendar: {
        min: 7,
        max: 21,
        week_schedule: [],
    },
    calendars: [],
    openModal: false,
    enableForm: true,
    active: null,
    slot: {
        date: "",
        start: "",
        end: "",
        day: "",
    },
    buildingFilter: null,
    classroomFilter: null,
    scholarSubjectsByTeacherModal: false,
    compactedClasses: [],
    schedules: [],
    enrollments: [],
    calendarDisponibility: {
        isDisponible: true,
        errorsArray: [],
        successText: "Sin conflictos en horario",
        canRegister: true,
    },
    extracurricularSchedules: [],
    academicGroupId: null,
};

export const calendarScheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.calendarScheduleSetCalendar:
            return {
                ...state,
                calendar: action.payload,
            };
        case types.calendarScheduleSetCalendars:
            return {
                ...state,
                calendars: action.payload,
            };
        case types.calendarScheduleSetSchedulesAcademicGroupId:
            return {
                ...state,
                academicGroupId: action.payload,
            };
        case types.calendarScheduleSetCleanCalendar:
            return {
                ...state,
                calendar: initialState.calendar,
                calendars: initialState.calendars,
            };
        case types.calendarScheduleSetSchedulesList:
            return {
                ...state,
                schedules: action.payload,
            };
        case types.calendarScheduleOpenModal:
            return {
                ...state,
                openModal: action.payload,
            };

        case types.calendarScheduleCloseModal:
            return {
                ...state,
                openModal: false,
            };

        case types.calendarScheduleSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.calendarScheduleUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.calendarScheduleEnableForm:
            return {
                ...state,
                enableForm: true,
            };
        case types.calendarScheduleDisableForm:
            return {
                ...state,
                enableForm: false,
            };
        case types.calendarScheduleSetSlot:
            return {
                ...state,
                slot: action.payload,
            };
        case types.calendarScheduleSetBuilding:
            return {
                ...state,
                buildingFilter: action.payload,
            };
        case types.calendarScheduleClearBuilding:
            return {
                ...state,
                buildingFilter: null,
            };
        case types.calendarScheduleSetClassroom:
            return {
                ...state,
                classroomFilter: action.payload,
            };
        case types.calendarScheduleClearClassroom:
            return {
                ...state,
                classroomFilter: null,
            };
        case types.calendarScheduleOpenModalScholarSubjectsByTeacher:
            return {
                ...state,
                scholarSubjectsByTeacherModal: true,
            };

        case types.calendarScheduleCloseModalScholarSubjectsByTeacher:
            return {
                ...state,
                scholarSubjectsByTeacherModal: false,
            };
        case types.calendarSetCompactedSchedulesByCycle:
            return {
                ...state,
                compactedClasses: action.payload,
            };
        case types.calendarSetEnrollments:
            return {
                ...state,
                enrollments: action.payload,
            };

        case types.calendarSetEmptyEnrollments:
            return {
                ...state,
                enrollments: [],
            };

        case types.calendarSetCalendarDisponibility:
            return {
                ...state,
                calendarDisponibility: action.payload,
            };

        case types.calendarClearCalendarDisponibility:
            return {
                ...state,
                calendarDisponibility: initialState.calendarDisponibility,
            };

        case types.calendarExtracurricularSchedules:
            return {
                ...state,
                extracurricularSchedules: action.payload,
            };

        case types.calendarClearExtracurricularSchedules:
            return {
                ...state,
                extracurricularSchedules: initialState.extracurricularSchedules,
            };

        default:
            return state;
    }
};
