import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import { Call, cleanEmpty } from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    studentGrades: {},
    modal: false,
    active: null,
    cycle_id: "",
    reportGrades: {},
};

export const gradesByStudentApp = createSlice({
    name: "gradesByStudentApp",
    initialState,
    reducers: {
        setStudentGrades: (state, action) => {
            state.studentGrades = action.payload;
        },
        setCycleId: (state, action) => {
            state.cycle_id = action.payload;
        },
        setStudentsGrades: (state, action) => {
            state.reportGrades = action.payload;
        },
        toggleModal: (state, action) => {
            state.modal = !!action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        unsetActive: (state, action) => {
            state.active = null;
        },
    },
});

export const {
    setStudentGrades,
    setCycleId,
    setStudentsGrades,
    toggleModal,
    setActive,
    unsetActive,
} = gradesByStudentApp.actions;

export default gradesByStudentApp.reducer;

/// ACTIONS

export const startGetGradesForStudent = ({
    cycle_id,
    modality,
    decimalsOnParcials = true,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();

        if (cycle_id) {
            params.set("cycle_id", cycle_id);
        }

        params.set("modality", modality);
        params.set("decimalsOnParcials", decimalsOnParcials);

        const resp = await Call(
            "students/academic_services/grades",
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setStudentGrades(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startGetGradesForOperator = ({
    academic_record_id,
    cycle_id,
    modality,
    hideNoCurricular,
    decimalsOnParcials = false,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();
        if (cycle_id) {
            params.set("cycle_id", cycle_id);
        }
        if (hideNoCurricular) {
            params.set("hideNoCurricular", hideNoCurricular);
        }
        if (decimalsOnParcials) {
            params.set("decimalsOnParcials", decimalsOnParcials);
        }

        params.set("modality", modality);

        const resp = await Call(
            `operators/students/grades/${academic_record_id}`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setStudentGrades(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startUpdateGradesForOperator = (values, callback) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/students/grades/edit`,
            "PUT",
            cleanEmpty(values)
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Modificación exitosa.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 1000,
            });
            callback();
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startCreateGradesForOperator = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/students/grades/create`,
            "PUT",
            cleanEmpty(values)
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Modificación exitosa.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 1000,
            });

            let academic_record_id = resp.success.academic_record_id;
            let cycle_id = resp.success.cycle_id;
            let modality = values.modality;
            dispatch(
                startGetGradesForOperator({
                    academic_record_id,
                    cycle_id,
                    modality: modality,
                })
            );
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

// export const startShow = (academic_record) => {
//     return async (dispatch) => {
//         dispatch(startUILoading());

//         const resp = await Call(
//             `operators/reports/certificate/rigid_curriculum/${academic_record}`,
//             "GET"
//         );

//         if (resp.success) {
//             dispatch(setStudentsGrades(resp.success));
//         } else {
//             errorHandler(resp);
//         }

//         dispatch(stopUILoading());
//     };
// };
