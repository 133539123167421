import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormData,
    objectToFormData,
} from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";

const initialState = {
    pagination: {
        data: [],
        per_page: 25,
    },
    modalOpen: false,
    openPDF: false,
    active: null,
    list: [],
};

export const loadDocuments = createSlice({
    name: "loadDocuments",
    initialState,
    reducers: {
        setDocuments: (state, action) => {
            state.list = action.payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setOpenPDF: (state, action) => {
            state.openPDF = action.payload;
        },
        setOpenModal: (state) => {
            state.modalOpen = true;
        },
        setClose: (state) => {
            state.modalOpen = false;
        },
    },
});

export const {
    setDocuments,
    setPagination,
    setOpenPDF,
    setOpenModal,
    setClose,
    setActive,
} = loadDocuments.actions;

export default loadDocuments.reducer;

export const startShow = () => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`students/documents`, "GET");

        if (resp.success) {
            dispatch(setDocuments(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};
export const getDocumentsByAcademicRecord = (academic_record_id) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/documents/${academic_record_id}`,
            "GET"
        );

        if (resp.success) {
            dispatch(setDocuments(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startSave = (
    values,
    setErrors = () => {},
    callback = () => {}
) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "Confirmar esta acción",
            icon: "info",
            text: "Confirmo que el documento que estoy a punto de subir es correcto y cumple con los requisitos de calidad especificados. La calidad del documento es alta y legible.",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sí, confirmo",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
        });

        if (!values.autoapprouver) {
            delete values.autoapprouver;
        }

        if (result?.isConfirmed) {
            dispatch(startUILoading());
            const resp = await CallWithFormData(
                `students/documents/upload`,
                "POST",
                objectToFormData(values)
            );

            if (resp.success && !resp.success.all_documents_approved) {
                Swal.fire({
                    title: "Éxito",
                    text: "Se cargó el docuento.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                callback();
            } else if (resp.success && resp.success.all_documents_approved) {
                Swal.fire({
                    title: "Todos los documentos han sido aprobados",
                    text: "El estatus del estudiante ha sido actualizado a SOLICITANTE.",
                    icon: "success",
                }).then((result) => {
                    // Recargar la página al cerrar el Swal
                    if (
                        result.dismiss === Swal.DismissReason.close ||
                        result.dismiss === Swal.DismissReason.overlay
                    ) {
                        window.location.reload();
                    }
                });
            } else {
                errorHandler(resp, setErrors);
            }
            dispatch(stopUILoading());
        } else {
            errorHandler(resp, setErrors);
        }
    };
};

export const startDelete = (id, callback = () => {}) => {
    return async (dispatch) => {
        const result = await Swal.fire({
            title: "¿Estás seguro de eliminar?",
            icon: "warning",
            text: "No podrás revertir esta acción!",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Si, Eliminar!",
            confirmButtonColor: "#2e4a6c",
            cancelButtonColor: "#6c757d",
            input: "text",
            inputLabel:
                "Escribe la palabra 'eliminar', para confirmar esta operación.",
            inputAttributes: {
                autocapitalize: "off",
            },
        });

        if (result.isConfirmed) {
            dispatch(startUILoading());

            const resp = await Call(
                `students/documents/delete/${id}`,
                "DELETE"
            );

            if (resp.success) {
                Swal.fire({
                    title: "Éxito",
                    text: "Se eliminó el archivo.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 8000,
                });
                callback();
            } else {
                errorHandler(resp, setErrors);
            }
            dispatch(stopUILoading());
        }
    };
};

export const startShowDocuments = (
    { query, number_rows, all, status, document_type },
    setErrors = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const urlParams = new URLSearchParams();

        urlParams.set("q", query);
        urlParams.set("per_page", number_rows);
        urlParams.set("all", all);

        if (status) {
            urlParams.set(
                "status",
                status.map((d) => d.value)
            );
        }
        if (document_type) {
            urlParams.set(
                "document_type",
                document_type.map((d) => d.value)
            );
        }

        const resp = await Call(
            `students/documents/show`,
            "GET",
            urlParams.toString()
        );

        if (resp.success) {
            dispatch(setPagination(resp.success));
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};

export const startEditDocument = (
    id,
    values,
    fetchValues,
    setErrors = () => {}
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(`students/documents/edit/${id}`, "PUT", values);

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Actualización exitosa.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });

            dispatch(startShowDocuments(fetchValues));
            dispatch(setClose());
        } else {
            errorHandler(resp, setErrors);
        }

        dispatch(stopUILoading());
    };
};
