import { useDispatch, useSelector } from "react-redux";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";
import SidebarNavAccordion from "../../../SidebarNavAccordion";
import { useEffect } from "react";
import { startUIShowModulesCount } from "../../../../redux/actions/administrador/ui/ui";

export const Modules_Reports = () => {
    const dispatch = useDispatch();
    const { hasPermission } = useHasPermission();
    const { color_text_menu_accordion } = useSelector((state) => state.config);

    const canShowAccordion = hasPermission([
        "SCHOLARSHIPS_REPORT",
        "GRADES_BY_SUBJECT_REPORT",
        "GRADE_UPLOAD_REPORT",
        "ORIGIN_SCHOOLS_REPORT",
        "ACADEMIC_STATUS_REPORT",
        "GENERAL_AVERAGES_REPORT",
    ]);

    useEffect(() => {
        if (canShowAccordion) {
            dispatch(startUIShowModulesCount("Modules_Reports"));
        }
    }, [canShowAccordion]);

    return (
        <div>
            {true && (
                <SidebarNavAccordion
                    title={"Reportes"}
                    itemLevel={0}
                    color={color_text_menu_accordion}
                    iconLeftClassName="fa-solid fa-files fa-xl"
                >
                    {hasPermission("SCHOLARSHIPS_REPORT") && (
                        <NavLinkApp
                            name="Becas"
                            to="/app/school/scholarships/report"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-backpack c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("GRADES_BY_SUBJECT_REPORT") && (
                        <NavLinkApp
                            name="Calificaciones por materia"
                            to="/app/school/grade/report/by/subject"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-chart-simple c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("GRADE_UPLOAD_REPORT") && (
                        <NavLinkApp
                            name="Carga de calificaciones"
                            to="/app/school/grade/report"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-file-chart-pie c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("ORIGIN_SCHOOLS_REPORT") && (
                        <NavLinkApp
                            name="Escuelas de procedencia"
                            to="/app/students/report/origin-schools"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-school c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("ACADEMIC_STATUS_REPORT") && (
                        <NavLinkApp
                            name="Estatus académico"
                            to="/app/students/academic/status"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-wave-pulse c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("INSCRIPTIONS_BY_CYCLE_REPORT") && (
                        <NavLinkApp
                            name="Inscripciones por ciclo"
                            to="/app/students/report/inscriptions-cycle"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-arrows-spin c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                    {hasPermission("GENERAL_AVERAGES_REPORT") && (
                        <NavLinkApp
                            name="Promedios generales"
                            to="/app/school/grade/report/averages/general"
                            icon={
                                <IconApp iconClassName="fas fa-regular fa-ranking-star c-sidebar-nav-icon"></IconApp>
                            }
                        />
                    )}
                </SidebarNavAccordion>
            )}
        </div>
    );
};
