import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../../../helpers/errorHandler";
import { Call, CallWithFormDataFile, downloadBlob } from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import Swal from "sweetalert2";

const initialState = {
    report: {},
};

export const GradesBySubject = createSlice({
    name: "GradesBySubject",
    initialState,
    reducers: {
        setReport: (state, action) => {
            state.report = action.payload;
        },

        clearReport: (state) => {
            state.report = initialState.report;
        },
    },
});
export const { setReport, clearReport } = GradesBySubject.actions;

export default GradesBySubject.reducer;

// Actions
export const startShowGradesBySubject = ({
    curriculum_id,
    school_subjects_id,
    cycle_id,
    update_avgs = 0,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        if (update_avgs) {
            params.append("update_avgs", update_avgs);
        }

        const resp = await Call(
            `operators/students/grades/by/subject/${curriculum_id}/${school_subjects_id}/${cycle_id}`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setReport(resp.success));
            if (update_avgs) {
                Swal.fire({
                    title: "Éxito",
                    text: "Los promedios se han actualizado.",
                    icon: "success",
                    toast: true,
                    position: "bottom-end",
                    timer: 5000,
                });
            }
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startExportGradesBySubject = ({
    curriculum_id,
    school_subjects_id,
    cycle_id,
}) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();

        params.set("export", 1);

        const response = await CallWithFormDataFile(
            `operators/students/grades/by/subject/${curriculum_id}/${school_subjects_id}/${cycle_id}`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Reporte de calificaciones por materia");

        dispatch(stopUILoading());
    };
};
