import { types } from "../../../types";

const initialState = {
    // pagination: {
    //     data: [],
    //     per_page: 10
    // },
    // list: [],
    active: null,
    openForm: false,
    grades: [],
};

export const scheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.scheduleEmployeeSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.scheduleEmployeeUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.setGradesByGroup:
            return {
                ...state,
                grades: action.payload,
            };
        case types.UnSetGradesByGroup:
            return {
                ...state,
                grades: [],
            };
        default:
            return state;
    }
};
