import PropTypes from "prop-types";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

export const IconApp = ({
    iconClassName,
    color,
    onClick = () => {},
    description = "_",
    isClickable = false,
    href = "",
    target = "_self",
    fontSize = "",
    disabled = false,
}) => {
    let styleIcon = {};

    if (color) {
        styleIcon.color = color;
    }
    if (isClickable) {
        styleIcon.cursor = "pointer";
    }
    if (fontSize) {
        styleIcon.fontSize = fontSize;
    }

    return (
        <>
            {disabled ? (
                <OverlayTrigger
                    placement={description ? "bottom" : "click"}
                    overlay={
                        <Tooltip hidden={description === "_"}>
                            {description}
                        </Tooltip>
                    }
                >
                    <i
                        className={iconClassName + " disabled"}
                        aria-hidden="true"
                        style={styleIcon}
                    ></i>
                </OverlayTrigger>
            ) : (
                <OverlayTrigger
                    placement={description ? "bottom" : "click"}
                    overlay={
                        <Tooltip hidden={description === "_"}>
                            {description}
                        </Tooltip>
                    }
                >
                    {href ? (
                        <Link
                            to={href}
                            style={{ color: "inherit" }}
                            target={target}
                            rel="noopener noreferrer"
                        >
                            <i
                                className={iconClassName}
                                aria-hidden="true"
                                style={styleIcon}
                                onClick={onClick}
                            ></i>
                        </Link>
                    ) : (
                        <i
                            className={iconClassName}
                            aria-hidden="true"
                            style={styleIcon}
                            onClick={onClick}
                        ></i>
                    )}
                </OverlayTrigger>
            )}
        </>
    );
};

IconApp.propTypes = {
    iconClassName: PropTypes.string.isRequired,
    color: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
};
