import {
    CSidebar,
    CSidebarBrand,
    CSidebarMinimizer,
    CSidebarNav,
} from "@coreui/react";
import React from "react";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { startUIToggleSidebar } from "../../redux/actions/administrador/ui/ui";
import { IconApp } from "../IconApp";
import { NavLinkApp } from "../NavLinkApp";
import { TeacherModules } from "./modules/teachers/TeacherModules";
import { UsersModules } from "./modules/users/UsersModules";

//Redux Toolkit
import { toggleMinimize } from "../../redux/slices/sidebarSlice";
import { StudentModules } from "./modules/student/StudentModules";

const TheSidebar = () => {
    const dispatch = useDispatch();
    const { sidebarShow, uiShowModulesCount } = useSelector(
        (state) => state.ui
    );
    const { minimized } = useSelector((state) => state.sidebar);
    const { color_lat, color_nav, logo, tiny_name } = useSelector(
        (state) => state.config
    );

    const toggleSidebar = (val) => {
        dispatch(startUIToggleSidebar(val));
    };

    // const [minimizer, setMinimizer] = React.useState(true);
 
    return (
        <CSidebar
            show={sidebarShow}
            onShowChange={(val) => toggleSidebar(val)}
            size={"sm"}
            style={{ backgroundColor: color_lat }}
            // marginBottom: "50px"
        >
            <CSidebarBrand
                className="d-md-down-none"
                to="/"
                style={{ backgroundColor: color_nav }}
            >
                <Image
                    src={`/storage/${logo}`}
                    rounded
                    style={{
                        height: 40,
                        objectFit: "scale-down",
                    }}
                    alt={tiny_name}
                    className="c-sidebar-brand-full"
                />

                <Image
                    src={`/storage/${logo}`}
                    rounded
                    style={{
                        height: 22,
                        objectFit: "scale-down",
                    }}
                    alt={tiny_name}
                    className="c-sidebar-brand c-sidebar-brand-minimized"
                />
            </CSidebarBrand>
            <CSidebarNav>
                <NavLinkApp
                    name="Inicio"
                    to="/app/home"
                    icon={
                        <IconApp iconClassName="fas fa-home c-sidebar-nav-icon"></IconApp>
                    }
                />

                <UsersModules />
                <TeacherModules />
                <StudentModules />
            </CSidebarNav>
            <div
                style={{ width: minimized ? "56px" : "192px" }}
                onClick={() => dispatch(toggleMinimize())}
            >
                <CSidebarMinimizer className="c-d-md-down-none" />
            </div>
        </CSidebar>
    );
};

export default React.memo(TheSidebar);
