import { lazy } from "react";

const ProfileTeacherScreen = lazy(() =>
    import("../../views/teachers/v2/session/profile/ProfileScreen")
);

const TeacherPunches = lazy(() =>
    import("../../views/teachers/v2/session/TeacherPunches")
);

const TeacherOclock = lazy(() =>
    import("../../views/teachers/v2/session/oclock/TeacherOclock")
);

const TeachersSchedule = lazy(() =>
    import("../../views/teachers/TeachersSchedule")
);

const TeacherGroupsScreen = lazy(() =>
    import("../../views/teachers/TeacherSession/Modules/TeacherGroups/TeacherGroupsScreen")
);

const TeacherSubjectsScreen = lazy(() =>
    import("../../views/teachers/TeacherSession/Modules/TeacherSubjects/TeacherSubjectsScreen")
);

const TeacherGroupById = lazy(() =>
    import("../../views/teachers/TeacherSession/Modules/TeacherGroups/TeacherGroupById")
);

const TeacherSubjectById = lazy(() =>
    import("../../views/teachers/TeacherSession/Modules/TeacherSubjects/TeacherSubjectById")
);

const TeacherExtraordinariesScreen = lazy(() =>
    import("../../views/teachers/TeacherSession/Modules/TeacherExtraordinaries/TeacherExtraordinariesScreen")
);

const TeacherOrdinariesScreen = lazy(() =>
    import("../../views/teachers/TeacherSession/Modules/TeacherOrdinaries/TeacherOrdinariesScreen")
);

const TeacherAttendancesScreen = lazy(() =>
    import(
        "../../views/teachers/TeacherSession/Modules/TeacherAttendances/TeacherAttendancesScreen"
    )
);
const TeacherAttendanceBySubject = lazy(() =>
    import(
        "../../views/teachers/TeacherSession/Modules/TeacherAttendances/TeacherAttendaceBySubject"
    )
);
const TeacherPlanningSheetsScreen = lazy(() =>
    import(
        "../../views/teachers/TeacherSession/Modules/TeacherPlanningSheets/TeacherPlanningSheetsScreen"
    )
);


export const TeachersRoutes = {
    ProfileTeacherScreen,
    TeacherPunches,
    TeacherOclock,
    TeachersSchedule,
    TeacherGroupsScreen,
    TeacherSubjectsScreen,
    TeacherExtraordinariesScreen,
    TeacherOrdinariesScreen,
    TeacherGroupById,
    TeacherSubjectById,
    TeacherAttendancesScreen,
    TeacherAttendanceBySubject,
    TeacherPlanningSheetsScreen
};

