import { CSidebarNavTitle } from "@coreui/react";
import React from "react";
import { useHasPermission } from "../../../../hooks/useHasPermission";
import { IconApp } from "../../../IconApp";
import { NavLinkApp } from "../../../NavLinkApp";

export const Modules_TeachersSession = () => {
    const { hasPermission } = useHasPermission();
    return (
        <div>
            {hasPermission("TEACHERS_PAYROLL_BY_TEACHERS") && (
                <CSidebarNavTitle>Docentes</CSidebarNavTitle>
            )}
            {hasPermission("TEACHER_CHECK_IN") && (
                <NavLinkApp
                    name="Reloj checador"
                    to={`/app/teacher/oclock`}
                    icon={
                        <IconApp iconClassName="fas fa-clock c-sidebar-nav-icon"></IconApp>
                    }
                />
            )}

            {hasPermission("TEACHERS_PAYROLL_BY_TEACHERS") && (
                <NavLinkApp
                    name="Nóminas"
                    to={`/app/teacher/cuts/punches`}
                    icon={
                        <IconApp iconClassName="fas fa-file-invoice-dollar c-sidebar-nav-icon"></IconApp>
                    }
                />
            )}

            {hasPermission("TEACHERS_CALENDAR") && (
                <NavLinkApp
                    name="Horario"
                    to={`/app/teacher/schedule`}
                    icon={
                        <IconApp iconClassName="fas fa-solid fa-person-chalkboard c-sidebar-nav-icon"></IconApp>
                    }
                />
            )}

            {/* Grupos docente */}
            {hasPermission("TEACHER_GROUPS") && (
                <NavLinkApp
                    name="Mis grupos"
                    to={`/app/teacher/groups`}
                    icon={
                        <IconApp iconClassName="fas fa-solid fa-screen-users c-sidebar-nav-icon"></IconApp>
                    }
                />
            )}

            {/* Materias docente */}
            {hasPermission("TEACHER_SUBJECTS") && (
                <NavLinkApp
                    name="Mis materias"
                    to={`/app/teacher/subjects`}
                    icon={
                        <IconApp iconClassName="fas fa-solid fa-books c-sidebar-nav-icon"></IconApp>
                    }
                />
            )}

            {/* Calificaciones docente */}
            {hasPermission("TEACHER_GRADES") && (
                <NavLinkApp
                    name="Calificaciones"
                    to={`/app/teacher/grades/ordinaries`}
                    icon={
                        <IconApp iconClassName="fas fa-solid fa-file-pen c-sidebar-nav-icon"></IconApp>
                    }
                />
            )}

            {/* Extraordinarios docente */}
            {hasPermission("TEACHER_EXTRAORDINARIES") && (
                <NavLinkApp
                    name="Extraordinarios"
                    to={`/app/teacher/grades/extraordinaries`}
                    icon={
                        <IconApp iconClassName="fa-light fa-file-pen c-sidebar-nav-icon"></IconApp>
                    }
                />
            )}

            {/*Asistencias */}
            {hasPermission("TEACHER_ATTENDENCE_BY_STUDENT") && (
                <NavLinkApp
                    name="Toma de asistencias"
                    to={`/app/teacher/attendance`}
                    icon={
                        <IconApp iconClassName="fa-regular fa-clipboard-user c-sidebar-nav-icon"></IconApp>
                    }
                />
            )}

            {/* Fichas de planeación */}
            {hasPermission("PLANNING_SHEETS") && (
                <NavLinkApp
                    name="Fichas de planeación"
                    to={`/app/teacher/planning-sheets`}
                    icon={
                        <IconApp iconClassName="fa-solid fa-file-spreadsheet c-sidebar-nav-icon"></IconApp>
                    }
                />
            )}
        </div>
    );
};
