import { types } from "../../../types";

const initialState = {
    list: [],
    list_group: [],
    pagination: {
        data: [],
        per_page: 5,
    },
    active: null,
    openForm: false,
    usersWithPermission: [],
};

export const modulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.modulesAll:
            return {
                ...state,
                list: action.payload,
            };
        case types.modulesGroupAll:
            return {
                ...state,
                list_group: action.payload,
            };
        case types.modulesShow:
            return {
                ...state,
                pagination: action.payload.pagination,
            };
        case types.moduleSetActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.moduleOpenForm:
            return {
                ...state,
                openForm: true,
            };
        case types.moduleCloseForm:
            return {
                ...state,
                openForm: false,
                active: false,
            };
        case types.moduleUsersWithPermission:
            return {
                ...state,
                usersWithPermission: action.payload,
            };
        default:
            return state;
    }
};
