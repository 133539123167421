import { createSlice } from "@reduxjs/toolkit";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
} from "../../../helpers/fetch";
import { errorHandler } from "../../../helpers/errorHandler";
import Swal from "sweetalert2";
import { startStudentSetActive } from "../../actions/students/students";

const initialState = {
    pagination: {
        data: [],
        per_page: 50,
    },
    showModal: false,
    active: null,
    report: {
        pagination: {
            data: [],
            per_page: 10,
            total: 0,
        },
    },
    condensed: null,
};

export const statusChange = createSlice({
    name: "statusChange",
    initialState,
    reducers: {
        setSetActive: (state, action) => {
            state.active = action.payload;
        },
        startUnsetActive: (state) => {
            state.active = null;
        },
        openModal: (state) => {
            state.showModal = true;
        },
        closeModal: (state) => {
            state.showModal = false;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setPaginationReport: (state, action) => {
            state.report.pagination = action.payload;
        },
        clearPaginationReport: (state) => {
            state.report.pagination = initialState.report.pagination;
        },
        seListReport: (state, action) => {
            state.report.list = action.payload;
        },
        setCondensed: (state, action) => {
            state.condensed = action.payload;
        },
    },
});

export const {
    setSetActive,
    startUnsetActive,
    openModal,
    closeModal,
    setPagination,
    setPaginationReport,
    clearPaginationReport,
    seListReport,
    setCondensed,
} = statusChange.actions;

export default statusChange.reducer;

// Actions
export const startShow = (academic_record, values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const resp = await Call(
            `operators/students/statuses/${academic_record}`,
            "GET",
            values
        );

        if (resp.success) {
            dispatch(setPagination(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startSave = (academic_record, values) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(
            `operators/students/statuses/${academic_record.id}`,
            "post",
            values
        );

        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Modificación exitosa.",
                icon: "success",
                toast: true,
                position: "bottom-end",
                timer: 8000,
            });
            dispatch(startShow(academic_record.id, values));
            //Actualizar el estaus del alumno activo

            // academic_record.status = values.status
            dispatch(
                startStudentSetActive({
                    ...academic_record,
                    status: values.status,
                })
            );
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startShowReport = ({
    page = 1,
    query = "",
    number_rows = 10,
    asList = 0,
    from,
    to,
    status,
}) => {
    return async (dispatch) => {
        if (!asList) {
            dispatch(startUILoading());
        }

        const params = new URLSearchParams();

        if (asList) {
            params.set("asList", asList);
        } else {
            params.set("page", page);
            params.set("q", query);
            params.set("per_page", number_rows);
            if (from) {
                params.set("from", from);
            }
            if (to) {
                params.set("to", to);
            }
            if (status) {
                params.set("status", status);
            }
        }

        const resp = await Call(
            `operators/students/statuses/report`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setPaginationReport(resp.success));
        } else {
            errorHandler(resp);
        }

        if (!asList) {
            dispatch(stopUILoading());
        }
    };
};

export const startShowCondensed = ({ from, to, status }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("isCondensed", 1);

        if (from) {
            params.set("from", from);
        }
        if (to) {
            params.set("to", to);
        }
        if (status) {
            params.set("status", status);
        }

        const resp = await Call(
            `operators/students/statuses/report`,
            "GET",
            params
        );

        if (resp.success) {
            dispatch(setCondensed(resp.success));
        } else {
            errorHandler(resp);
        }

        dispatch(stopUILoading());
    };
};

export const startExportReport = ({ from, to, status }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("export", 1);

        if (from) {
            params.set("from", from);
        }
        if (to) {
            params.set("to", to);
        }
        if (status) {
            params.set("status", status);
        }

        const response = await CallWithFormDataFile(
            `operators/students/statuses/report`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Reporte de estatus académico de estudiantes");

        dispatch(stopUILoading());
    };
};
