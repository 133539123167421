import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { errorHandler } from "../../../helpers/errorHandler";
import {
    Call,
    CallWithFormDataFile,
    downloadBlob,
    objectToFormData,
} from "../../../helpers/fetch";
import {
    startUILoading,
    stopUILoading,
} from "../../actions/administrador/ui/ui";

const initialState = {
    response: {
        data: [],
        per_page: 25,
    },
    active: null,
    modalCopyScholarships: false,
    modalScholarshipsForm: false,
    modalScholarshipsFormCreate: false,
    modalScholarshipsFormCreate2: false,
    copiedResponse: {
        data: [],
        per_page: 25,
    },
    allScholarshipsIdArray: [],
    report: null,
};

export const scholarshipScreen = createSlice({
    name: "scholarshipScreen",
    initialState,
    reducers: {
        setScholarships: (state, action) => {
            state.response = action.payload;
        },
        toggleModalCopyScholarships: (state) => {
            state.modalCopyScholarships = !state.modalCopyScholarships;
        },
        toggleModalScholarshipsForm: (state) => {
            state.modalScholarshipsForm = !state.modalScholarshipsForm;
        },
        toggleModalScholarshipsFormCreate: (state) => {
            state.modalScholarshipsFormCreate =
                !state.modalScholarshipsFormCreate;
        },
        toggleModalScholarshipsFormCreate2: (state) => {
            state.modalScholarshipsFormCreate =
                !state.modalScholarshipsFormCreate;
        },
        setCopiedScholarships: (state, action) => {
            state.copiedResponse = action.payload;
        },
        setAllScholarshipsIdArray: (state, action) => {
            state.allScholarshipsIdArray = action.payload;
        },
        startScholarshipsSetActive: (state, action) => {
            state.active = action.payload;
        },
        startScholarshipsUnsetActive: (state) => {
            state.active = null;
        },
        setReport: (state, action) => {
            state.report = action.payload;
        },
        clearReport: (state) => {
            state.report = initialState.report;
        },
    },
});

export const {
    setScholarships,
    toggleModalCopyScholarships,
    toggleModalScholarshipsForm,
    toggleModalScholarshipsFormCreate,
    toggleModalScholarshipsFormCreate2,
    setCopiedScholarships,
    setAllScholarshipsIdArray,
    startScholarshipsSetActive,
    startScholarshipsUnsetActive,
    setReport,
    clearReport,
} = scholarshipScreen.actions;

export default scholarshipScreen.reducer;

export const startShow = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const params = new URLSearchParams();

        params.set("page", values.page);
        params.set("q", values.query);
        params.set("per_page", values.number_rows);

        if (values.status !== "") {
            params.set("status", values.status);
        }

        if (values.cycle.length > 0) {
            const joinCycles = values.cycle
                ?.map((cycle) => cycle?.value)
                ?.join(",");
            params.set("cycle_ids", joinCycles);
        }

        if (values.types !== "") {
            params.set("types", values.types);
        }

        if (values.percentage !== "") {
            params.set("percentage", values.percentage);
        }

        if (values.curricula.length > 0) {
            const joinCurriculas = values.curricula
                ?.map((curricula) => curricula?.value)
                ?.join(",");
            params.set("curricula_ids", joinCurriculas);
        }

        const resp = await Call(
            `operators/scholarship`,
            "GET",
            params.toString()
        );

        if (resp.success) {
            dispatch(setScholarships(resp?.success));
        }

        dispatch(stopUILoading());
    };
};

export const startCopyScholarships = (values, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const send = {
            ...values,
            per_page: values.number_rows,
            q: values.query,
            page: values.page,
        };
        const resp = await Call(
            `operators/scholarship/calculate`,
            "POST",
            send
        );
        if (resp.success) {
            dispatch(setCopiedScholarships(resp?.success));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startGetAllScholarshipsId = (values, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const send = {
            curr_cycle: values.curr_cycle,
            new_cycle: values.new_cycle,
            all: 1,
        };
        const resp = await Call(
            `operators/scholarship/calculate`,
            "POST",
            send
        );
        if (resp.success)
            dispatch(
                setAllScholarshipsIdArray(
                    resp?.success?.map((object) => object.id)
                )
            );
        else errorHandler(resp, setErrors);
        dispatch(stopUILoading());
    };
};

export const startUpdateScholarship = (
    { status, note, percentage },
    id,
    pagination,
    callback,
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const resp = await Call(`operators/scholarship/${id}`, "POST", {
            status,
            note,
            percentage,
        });
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
            dispatch(startShow(pagination));
            callback();
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startTransferScholarships = (
    array,
    new_cycle,
    curr_cycle,
    pagination,
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const joinedStr = Array.from(array).join(",");
        const send = { id: joinedStr, new_cycle, curr_cycle: curr_cycle.id };
        const resp = await Call(
            `operators/scholarship/renew/scholarship`,
            "POST",
            send
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
            dispatch(startShow(pagination));
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startExportScholarships = (
    { curr_cycle, new_cycle },
    setErrors
) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const response = await CallWithFormDataFile(
            `operators/scholarship/calculate`,
            "POST",
            objectToFormData({
                curr_cycle: curr_cycle,
                new_cycle: new_cycle,
                export: 1,
                all: 0,
            })
        );
        downloadBlob(response, "Reporte de becas");

        dispatch(stopUILoading());
    };
};

export const startExportShow = (values) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const params = new URLSearchParams();

        params.set("export", 1);
        if (values.status !== "") params.set("status", values.status);
        if (values.cycle !== "") params.set("cycle_id", values.cycle);
        if (values.types !== "") params.set("types", values.types);

        const response = await CallWithFormDataFile(
            `operators/scholarship`,
            "GET",
            params.toString()
        );
        downloadBlob(response, "Reporte de becas");

        dispatch(stopUILoading());
    };
};

export const updateActiveScholarshipsPeriod = (values, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());
        const {
            is_active_four_mont_period_scholarship,
            is_active_six_mont_period_scholarship,
        } = values;
        const resp = await Call(`auth/config/save`, "POST", {
            is_active_four_mont_period_scholarship,
            is_active_six_mont_period_scholarship,
        });
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startCreateScholarship = (values, callback, setErrors) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const formData = new FormData();

        formData.append("academic_record_id", values.academic_record_id);
        formData.append("curricula_id", values.curricula_id);
        formData.append("status", values.status);
        formData.append("percentage", values.percentage);
        formData.append("scholarship_type", values.scholarship_type);

        if (values.document) {
            formData.append("document", values.document);
        }

        formData.append("average", values.average);
        formData.append("curricula_name", values.average);
        formData.append("cycle_id", values.cycle_id);

        const resp = await CallWithFormDataFile(
            `operators/scholarship`,
            "POST",
            formData
        );
        if (resp.success) {
            Swal.fire({
                title: "Éxito",
                text: "Registro exitoso.",
                icon: "success",
            });
            dispatch(toggleModalScholarshipsFormCreate());
            callback();
        } else {
            errorHandler(resp, setErrors);
        }
        dispatch(stopUILoading());
    };
};

export const startShowReport = ({ cycles, type }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const cycles_data = `${cycles.year}-${cycles.sub_cycle}`;

        const params = new URLSearchParams();

        params.set("cycles", cycles_data);
        if (type) {
            params.set("type", type);
        }

        const resp = await Call(`operators/scholarship/report`, "GET", params);

        if (resp.success) {
            dispatch(setReport(resp.success));
        } else {
            errorHandler(resp);
        }
        dispatch(stopUILoading());
    };
};

export const startExportReport = ({ cycles, type }) => {
    return async (dispatch) => {
        dispatch(startUILoading());

        const cycles_data = `${cycles.year}-${cycles.sub_cycle}`;

        const params = new URLSearchParams();

        params.set("export", 1);
        params.set("cycles", cycles_data);
        if (type) {
            params.set("type", type);
        }

        const response = await CallWithFormDataFile(
            `operators/scholarship/report`,
            "GET",
            params.toString()
        );
        downloadBlob(response, `Reporte de becas - Ciclo ${cycles_data}`);

        dispatch(stopUILoading());
    };
};

export const startUpdate = () => {};

export const startDestroy = () => {};
